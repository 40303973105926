::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #283c92;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #283c92;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #283c92;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #283c92;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(40, 60, 146, 0.8);
  border-radius: 10px;
  height: 200px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::placeholder {
  color: rgba(40, 60, 146, 0.5);
}

.search-bar {
  font-family: "Poppins";
  font-size: 15px;
  border: none;
  outline: none;
  width: -webkit-fill-available;
  height: 40px;
  padding: 5px 15px;
  background-color: rgba(40, 60, 146, 0.11);
  border-radius: 6px;
}

.password-textinput {
  font-family: "Poppins";
  font-size: 15px;
  width: -webkit-fill-available;
  height: 40px;
  padding: 5px 15px;
  border: none;
  outline: none;
  background-color: transparent;
}

.text-field {
  font-family: "Poppins";
  font-size: 15px;
  border: none;
  outline: none;
  width: -webkit-fill-available;
  height: 40px;
  padding: 5px 15px;
  background-color: rgba(40, 60, 146, 0.11);
  border-radius: 6px;
  margin-bottom: 6px;
}

.textinput {
  font-family: "Poppins";
  font-size: 15px;
  border: none;
  outline: none;
  width: -webkit-fill-available;
  height: 40px;
  padding: 5px 15px;
  border-radius: 6px;
}

.search-bar:disabled {
  background-color: rgba(53, 53, 53, 0.11);
  color: rgba(74, 74, 74, 0.5);
}

.autocomplete-select {
  background-color: rgba(40, 60, 146, 0.11);
  font-family: "Poppins";
  font-size: 15px;
  height: 50px;
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 5px 15px;
  appearance: none;
  background-image: url("./assets/down-arrow.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 15px);
  background-size: 15px;
}

.text-area {
  font-family: "Poppins";
  font-size: 15px;
  border: none;
  outline: none;
  padding: 15px;
  background-color: rgba(40, 60, 146, 0.11);
  border-radius: 6px;
  resize: vertical;
}

.text-area-copy {
  font-family: "Poppins";
  font-size: 15px;
  border: none;
  outline: none;
  padding: 15px;
  background-color: rgba(40, 60, 146, 0.11);
  border-radius: 6px;
  resize: vertical;
  width: -webkit-fill-available;
}

.category-btn:hover {
  background-color: #ffffff !important;
  color: #283c92 !important;
}

.category-btn:hover span {
  color: #283c92 !important;
}

.category-btn {
  font-weight: 500 !important;
  text-transform: capitalize;
  font-family: "Poppins", "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
  padding: 7px 15px;
  margin: 10px 0px;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.error-message {
  font-family: "Poppins";
  font-size: 14px;
  padding-left: 2px;
  padding-top: 2px;
  color: #ec5353;
  margin: 0px;
}

.MuiDataGrid-row:nth-child(odd) {
  background-color: #f8f8f8;
}

.MuiDataGrid-row:nth-child(even) {
  background-color: #ffffff;
}

.MuiInputBase-root {
  color: #283c92;
}

.MuiFormControl-root {
  background-color: rgba(40, 60, 146, 0.11);
}

.MuiInputBase-input {
  padding: 10px;
  border: none;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-width: 0;
  border-color: transparent;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline:focus {
  border-color: #283c92;
  border-width: 1px;
}

.MuiGrid-item {
  transition: all 0.5s;
}

.mui-datepicker input {
  height: 40px;
  padding: 5px 15px;
}

.mui-datepicker fieldset {
  outline: none !important;
  border: none;
}

.MuiAutocomplete-inputFocused {
  border: none;
  outline: none;
  border-width: 0;
}

.mui-checkbox {
  background-color: transparent !important;
  margin-top: 10px !important;
}

.mui-checkbox label span {
  color: #283c92 !important;
}

/* Login Logo */
@media (max-width: 900px) {
  .login-logo {
    width: 30%;
    transition: all 0.5s;
  }
}

.swal2-html-container {
  font-family: "Poppins";
  margin: 0 !important;
  color: #283c92 !important;
  text-transform: capitalize;
}

.swal2-styled {
  padding: 0.625em 3em !important;
}

.swal2-styled:hover {
  background-image: none !important;
}

.swal2-actions {
  margin: 1em auto 0 !important;
}

.swal2-styled.swal2-confirm {
  background-color: #283c92 !important;
  color: #ffffff !important;
  border: 1px solid #283c92 !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-styled.swal2-cancel {
  background-color: #ffffff !important;
  color: #283c92 !important;
  border: 1px solid #283c92 !important;
}

.swal2-styled.swal2-cancel:focus {
  box-shadow: none !important;
}
